import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import * as Yup from "yup";

import Modal from "../../../components/Modal";
import api from "../../../services/api";
import handleMessageError from "../../../utils/handleMessageError";
import { Container, CompanyContent, ButtonsContainer } from "./styles";

interface ICompanyData {
  id: string;
  name: string;
  cnpj: string;
  inscricao_estadual: string;
  contact_email: string;
  phone: string;
  approved_by_user_id: string;
  created_at: string;
  updated_at: string;
  address: {
    state: string;
    city: string;
    zipcode: string;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;
    created_at: string;
    updated_at: string;
  };
}
interface ICompanySolicitationData extends ICompanyData {
  company: ICompanyData;
}

const CompanyUpdate: React.FC = () => {
  const [companyRegister, setCompanyRegister] = useState<
    ICompanySolicitationData
  >();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const { register_id } = useParams<{ register_id: string }>();

  const history = useHistory();

  useEffect(() => {
    api
      .get(`companies/requests/${register_id}/updates`)
      .then(response => {
        const register = response.data;

        setCompanyRegister(register);
      })
      .catch(error => {
        toast.error(
          `Falha ao buscar informações. ${handleMessageError(error)}`,
        );

        history.goBack();
      });
  }, [register_id, history]);

  const handleApproveUpdate = useCallback(async () => {
    const toastId = toast.info("Aprovando atualização...", {
      autoClose: false,
    });

    try {
      await api.post(
        `/companies/requests/updates/${companyRegister?.id}/approve`,
      );

      toast.update(toastId, {
        render: "Cadastro atualizado sucesso.",
        type: "success",
        autoClose: 3000,
      });

      history.push("/dashboard");
    } catch (error) {
      toast.update(toastId, {
        render: `Falha ao atualizar cadastro. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 6000,
      });
    }
  }, [companyRegister?.id, history]);

  const handleDeclineUpdate = useCallback(
    async description => {
      const schema = Yup.string().required();

      const toastId = toast.info("Recusando atualização...", {
        autoClose: false,
      });

      try {
        await schema.validate(description, { abortEarly: false });

        await api.post(
          `/companies/requests/updates/${companyRegister?.id}/decline`,
          { description },
        );

        toast.update(toastId, {
          render: "Atualização recusada. Em breve a empresa será notificada.",
          type: "warning",
          autoClose: 5000,
        });

        history.push("/dashboard");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          toast.update(toastId, {
            render: "Favor verifique os campos.",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao recusar cadastro. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 6000,
        });
      }
    },
    [companyRegister?.id, history],
  );

  if (!companyRegister) {
    return (
      <Container>
        <h1>Carregando...</h1>
      </Container>
    );
  }
  return (
    <Container>
      <main>
        <h1>Atualização cadastral de empresa</h1>
        <div>
          <CompanyContent>
            <h2>De</h2>
            <section>
              <div>
                <strong>Dados da empresa</strong>
                <hr />
                <p>
                  Nome: <strong>{companyRegister.company.name}</strong>
                </p>
                <p>
                  CNPJ: <strong>{companyRegister.company.cnpj}</strong>
                </p>
                <p>
                  Telefone de contato:
                  <strong> {companyRegister.company.phone}</strong>
                </p>
                <p>
                  E-mail de contato:
                  <strong> {companyRegister.company.contact_email}</strong>
                </p>
                <p>
                  Inscrição estadual:
                  <strong> {companyRegister.company.inscricao_estadual}</strong>
                </p>
              </div>
              <div>
                <strong>Endereço da empresa</strong>
                <hr />
                <p>
                  CEP:{" "}
                  <strong>{companyRegister.company.address.zipcode}</strong>
                </p>
                <p>
                  Estado:{" "}
                  <strong>{companyRegister.company.address.state}</strong>
                </p>
                <p>
                  Cidade:{" "}
                  <strong>{companyRegister.company.address.city}</strong>
                </p>
                <p>
                  Bairro:
                  <strong>
                    {" "}
                    {companyRegister.company.address.neighborhood}
                  </strong>
                </p>
                <p>
                  Rua:
                  <strong> {companyRegister.company.address.street}</strong>
                </p>
                <p>
                  Número:
                  <strong> {companyRegister.company.address.number}</strong>
                </p>
                <p>
                  Complemento:
                  <strong> {companyRegister.company.address.complement}</strong>
                </p>
              </div>
            </section>
          </CompanyContent>

          <CompanyContent>
            <h2>Para</h2>
            <section>
              <div>
                <strong>Dados da empresa</strong>
                <hr />
                <p>
                  Nome: <strong>{companyRegister.name}</strong>
                </p>
                <p>
                  CNPJ: <strong>{companyRegister.cnpj}</strong>
                </p>
                <p>
                  Telefone de contato: <strong>{companyRegister.phone}</strong>
                </p>
                <p>
                  E-mail de contato:
                  <strong> {companyRegister.contact_email}</strong>
                </p>
                <p>
                  Inscrição estadual:
                  <strong> {companyRegister.inscricao_estadual}</strong>
                </p>
              </div>

              <div>
                <strong>Endereço da empresa</strong>
                <hr />
                <p>
                  CEP: <strong>{companyRegister.address.zipcode}</strong>
                </p>
                <p>
                  Estado: <strong>{companyRegister.address.state}</strong>
                </p>
                <p>
                  Cidade: <strong>{companyRegister.address.city}</strong>
                </p>
                <p>
                  Bairro:
                  <strong> {companyRegister.address.neighborhood}</strong>
                </p>
                <p>
                  Rua:
                  <strong> {companyRegister.address.street}</strong>
                </p>
                <p>
                  Número:
                  <strong> {companyRegister.address.number}</strong>
                </p>
                <p>
                  Complemento:
                  <strong> {companyRegister.address.complement}</strong>
                </p>
              </div>
            </section>
          </CompanyContent>
        </div>

        <ButtonsContainer>
          <button type="button" onClick={handleApproveUpdate}>
            Aprovar cadastro da empresa
          </button>
          <button
            type="button"
            onClick={() => {
              setModalIsVisible(true);
            }}
          >
            Recusar
          </button>
        </ButtonsContainer>
      </main>

      {modalIsVisible && (
        <Modal
          title={`Qual é o motivo de recusa da atualização cadastral da ${companyRegister.name}`}
          description="Esse motivo será enviado ao e-mail de contato que a empresa cadastrou"
          changeVisibility={() => {
            setModalIsVisible(false);
          }}
          handleSubmit={handleDeclineUpdate}
        />
      )}
    </Container>
  );
};

export default CompanyUpdate;
