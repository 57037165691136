import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import * as Yup from "yup";

import Modal from "../../../components/Modal";
import api from "../../../services/api";
import formatDate from "../../../utils/formatDate";
import formatNumber from "../../../utils/formatNumber";
import handleMessageError from "../../../utils/handleMessageError";
import { Container, CustomerContent } from "./styles";

interface ICustomerData {
  id: string;
  name: string;
  uid: string;
  email: string;
  phone: string;
  disabled: boolean;
  created_at: string;
  updated_at: string;
}

const Customer: React.FC = () => {
  const [customer, setCustomer] = useState<ICustomerData>();
  const [loading, setLoading] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const { customer_id } = useParams<{ customer_id: string }>();

  useEffect(() => {
    api.get(`/customers/${customer_id}`).then(response => {
      setCustomer(response.data);
      setLoading(false);
    });
  }, [customer_id]);

  const handleDisableCustomer = useCallback(
    async description => {
      const schema = Yup.string().required();

      const toastId = toast.info("Inativando cliente...", {
        autoClose: false,
      });

      try {
        await schema.validate(description, { abortEarly: false });

        await api.post(`/customers/${customer_id}/status/disable`, {
          description,
        });

        setCustomer(state => {
          if (state) {
            return { ...state, disabled: true };
          }

          return state;
        });

        setModalIsVisible(false);

        toast.update(toastId, {
          render: "Cliente desativado. Em breve ele será notificado.",
          type: "warning",
          autoClose: 5000,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          toast.update(toastId, {
            render: "Favor verifique os campos.",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao inativar cliente. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 6000,
        });
      }
    },
    [customer_id],
  );

  const handleActiveCompany = useCallback(async () => {
    const toastId = toast.info("Ativando cliente...", {
      autoClose: false,
    });

    try {
      await api.post(`/customers/${customer_id}/status/active`);

      setCustomer(state => {
        if (state) {
          return { ...state, disabled: false };
        }

        return state;
      });

      toast.update(toastId, {
        render: "Cliente ativado. Em breve ele será notificado.",
        type: "success",
        autoClose: 5000,
      });
    } catch (error) {
      toast.update(toastId, {
        render: `Falha ao ativar cliente. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 6000,
      });
    }
  }, [customer_id]);

  if (!customer) {
    return (
      <Container>
        <main>
          <h1>{loading ? "Carregando..." : "Nenhum cliente encontrado"}</h1>
        </main>
      </Container>
    );
  }

  return (
    <Container>
      <main>
        <section>
          <header>
            <h1>Perfil do cliente</h1>
          </header>
          <CustomerContent>
            <section>
              <div>
                <strong>Dados</strong>
                <hr />
                <p>
                  Nome: <strong>{customer.name}</strong>
                </p>
                <p>
                  {customer.uid.length > 11 ? "CNPJ" : "CPF"}:{" "}
                  <strong>
                    {formatNumber({
                      data: customer.uid,
                      type: customer.uid.length > 11 ? "cnpj" : "cpf",
                    })}
                  </strong>
                </p>
                <p>
                  Telefone de contato:
                  <strong> {customer.phone}</strong>
                </p>
                <p>
                  E-mail:
                  <strong> {customer.email}</strong>
                </p>
              </div>

              <div>
                <strong>Dados adicionais</strong>
                <hr />

                <p>
                  Cadastrado em:
                  <strong> {formatDate(customer.created_at)}</strong>
                </p>
                <p>
                  Status:
                  <strong>
                    {customer.disabled ? " Desativada" : " Ativado"}
                  </strong>
                </p>
              </div>
            </section>
            {customer.disabled ? (
              <button onClick={handleActiveCompany}>Ativar cliente</button>
            ) : (
              <button
                onClick={() => {
                  setModalIsVisible(true);
                }}
              >
                Inativar cliente
              </button>
            )}
          </CustomerContent>
        </section>
      </main>

      {modalIsVisible && (
        <Modal
          title={`Qual é o motivo de inativar o ${customer.name}`}
          handleSubmit={handleDisableCustomer}
          description="Esse motivo será enviado ao e-mail do cliente"
          changeVisibility={() => {
            setModalIsVisible(false);
          }}
        />
      )}
    </Container>
  );
};

export default Customer;
