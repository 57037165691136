import React from "react";

import Header from "../../../components/Header";
import { useAuth } from "../../../hooks/auth";
import { Wrapper } from "./styles";

const DefaultLayout: React.FC = ({ children }) => {
  const { user } = useAuth();

  return (
    <>
      {user && <Header />}
      <Wrapper>{children}</Wrapper>
    </>
  );
};

export default DefaultLayout;
