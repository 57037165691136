import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 24px;

  h1 {
    margin-bottom: 24px;
  }
`;

export const FormContent = styled.section`
  background: #fff;
  border: 1px solid #c5d0da;
  border-radius: 4px;

  padding: 16px 40px;

  form {
    display: flex;
    flex-direction: column;
  }

  & + section {
    margin-top: 24px;
  }
`;

export const InputsContainer = styled.section`
  h2 {
    margin-bottom: 8px;
    margin-left: 16px;

    font-size: 2rem;
    font-weight: normal;
  }

  hr {
    height: 2px;
    border: 0;
    background: #c5d0da;

    margin-bottom: 8px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px 16px;

    max-width: 650px;
    margin: 0 auto;
  }

  & + section {
    margin-top: 24px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  button {
    padding: 8px 32px;
    font-weight: 500;
    font-size: 2rem;

    border-radius: 8px;

    & + button {
      margin-top: 24px;
    }
  }
`;
