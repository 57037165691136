import React, {
  useEffect,
  useRef,
  CSSProperties,
  useMemo,
  useState,
} from "react";
import ReactInputMask, { Props as InputProps } from "react-input-mask";

import { useField } from "@unform/core";

import { Container, Error } from "../styles";

interface IInputMaskProps extends Omit<InputProps, "mask"> {
  name: string;
  label: string;
  containerStyle?: CSSProperties;
  maskType: "cep" | "cpf" | "cnpj" | "phone";
}

const InputMask: React.FC<IInputMaskProps> = ({
  label,
  name,
  maskType,
  containerStyle = {},
  ...rest
}) => {
  const inputRef = useRef(null);

  const { defaultValue, error, fieldName, registerField } = useField(name);
  const [inputDataValue, setInputDataValue] = useState(String(defaultValue));

  const maskInput = useMemo(() => {
    let mask = "";

    switch (maskType) {
      case "cep":
        mask = "99999-999";
        break;
      case "cnpj":
        mask = "99.999.999/9999-99";

        break;
      case "cpf":
        mask = "999.999.999-99";

        break;
      case "phone":
        mask = "+55 (99) 9 9999 9999";

        break;
      default:
        null;
        break;
    }
    return mask;
  }, [maskType]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: any) {
        let inputValue = ref.value as string;

        switch (maskType) {
          case "cep":
            inputValue = inputValue.replace("-", "");

            break;
          case "cnpj":
            inputValue = inputValue.replaceAll(".", "");
            inputValue = inputValue.replaceAll("-", "");
            inputValue = inputValue.replaceAll("/", "");

            break;
          case "cpf":
            inputValue = inputValue.replace(".", "");
            inputValue = inputValue.replace("-", "");

            break;
          case "phone":
            inputValue = inputValue.replaceAll(" ", "");
            inputValue = inputValue.replace("(", "");
            inputValue = inputValue.replace(")", "");

            break;
          default:
            null;
            break;
        }
        return inputValue;
      },
      setValue(ref, value) {
        setInputDataValue(String(value));
      },
    });
  }, [registerField, fieldName, maskType]);

  return (
    <Container isErrored={!!error} style={containerStyle}>
      <label htmlFor={fieldName}>{label}</label>
      <ReactInputMask
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        mask={maskInput}
        onChange={e => {
          const { value } = e.target;
          setInputDataValue(value);
        }}
        value={inputDataValue}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default InputMask;
