import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;
  height: 100%;

  padding: 16px;

  border: 1px solid rgba(0, 0, 0, 0.2);

  section {
    hr {
      margin-bottom: 16px;
    }

    & + section {
      margin-top: 24px;
    }
  }
`;

export const CompanyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  div {
    display: flex;
    flex-direction: column;
    padding: 4px 8px;

    border: 2px solid rgba(0, 0, 0, 0.2);

    strong + strong {
      margin-top: 4px;
    }

    span {
      margin-top: 8px;
    }

    a {
      text-align: center;

      margin: 8px auto 0;
      padding: 4px 16px;

      background: #ddd;

      border-radius: 8px;
    }
  }
`;
