import React from "react";
import { MdBusiness, MdDashboard, MdPeople } from "react-icons/md";
import { Link } from "react-router-dom";

import { useAuth } from "../../hooks/auth";
import { Container, Content, PageItem, UserContent } from "./styles";

const Header: React.FC = () => {
  const { user } = useAuth();
  return (
    <Container>
      <Content>
        <ul>
          <PageItem>
            <Link to="/dashboard">
              <MdDashboard />
              Home
            </Link>
          </PageItem>
          <PageItem>
            <Link to="/companies">
              <MdBusiness />
              Empresas
            </Link>
          </PageItem>
          <PageItem>
            <Link to="/customers">
              <MdPeople />
              Clientes
            </Link>
          </PageItem>
          {/* <li>
            <Link to="/support">Suporte</Link>
          </li>
          <li>
            <Link to="/administration">Administração</Link>
          </li> */}
        </ul>

        <UserContent>
          {/* <button>Notificações</button> */}
          <div>
            <strong>{user.name}</strong>
            <Link to="/profile">Meu perfil</Link>
          </div>
          <img
            src="https://cdn.imgbin.com/2/8/19/imgbin-computer-icons-user-profile-avatar-avatar-WXX8hueXWtzF4cYqAeN7UnSgP.jpg"
            alt={user.name}
          />
        </UserContent>
      </Content>
    </Container>
  );
};

export default Header;
