import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  margin-top: 16%;
`;

export const Content = styled.div`
  border: 1px solid #000;
  border-radius: 4px;

  background: #fff;

  padding: 16px 64px;

  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 450px;
  width: 100%;

  form {
    width: 100%;

    div + div {
      margin-top: 16px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;

      padding: 8px 16px;
      border-radius: 8px;
      margin: 24px 0 40px;

      font-size: 2rem;

      svg {
        margin-right: 8px;
      }
    }
  }

  section {
    text-align: center;

    a:hover {
      text-decoration: underline;
    }
  }
`;
