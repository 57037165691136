import styled from "styled-components";

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #eee;

  border-radius: 8px;

  padding: 8px 32px 24px;

  header {
    margin-left: auto;

    button {
      font-size: 4rem;
    }
  }

  textarea {
    padding: 8px 16px;
    margin: 16px 0;

    width: 100%;

    resize: none;
  }

  footer {
    display: flex;
    align-items: center;

    span {
      width: 100%;
      max-width: 360px;
      text-align: center;
    }

    button {
      background: #ddd;

      border-radius: 8px;
      padding: 8px 16px;
      margin-left: 16px;
    }
  }
`;
