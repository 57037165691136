import React, { useCallback, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

import { Form } from "@unform/web";

import Input from "../../components/form/Input";
import api from "../../services/api";
import formatDate from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";
import { Container, CompanyContainer } from "./styles";

interface ICompanyData {
  id: string;
  name: string;
  cnpj: string;
  created_at: string;
  updated_at: string;
}

const Products: React.FC = () => {
  const [companies, setCompanies] = useState<ICompanyData[]>([]);

  useEffect(() => {
    api.get("/companies").then(response => {
      setCompanies(response.data);
    });
  }, []);

  const handleSubmit = useCallback(data => {
    api
      .get("/companies", {
        params: data,
      })
      .then(response => {
        setCompanies(response.data);
      });
  }, []);

  return (
    <Container>
      <header>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Pesquise uma empresa por nome ou CNPJ"
            name="search_uid"
            type="search"
            placeholder="Digite o nome ou CNPJ"
          />
        </Form>
      </header>
      <main>
        <ul>
          {companies.map(company => (
            <CompanyContainer key={company.id}>
              <img
                src="https://image.flaticon.com/icons/png/512/1962/1962551.png"
                alt={company.name}
              />
              <div>
                <span>
                  <strong>{company.id}</strong>
                </span>
                <section>
                  <p>
                    <strong>{company.name}</strong>
                  </p>
                  <p>
                    <strong>
                      {formatNumber({
                        data: company.cnpj,
                        type: company.cnpj.length > 12 ? "cnpj" : "cpf",
                      })}
                    </strong>
                  </p>
                </section>
                <section>
                  <p>
                    Data de cadastramento:
                    <strong> {formatDate(company.created_at)}</strong>
                  </p>
                  <p>
                    Data da ultima atualização:
                    <strong> {formatDate(company.updated_at)}</strong>
                  </p>
                </section>
              </div>

              <Link to={`/companies/${company.id}`}>
                <MdKeyboardArrowRight size={40} />
              </Link>
            </CompanyContainer>
          ))}
        </ul>
      </main>
    </Container>
  );
};

export default Products;
