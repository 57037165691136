import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;

  background: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #000;

  header {
    display: flex;

    width: 100%;

    margin-bottom: 32px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 4px 8px;
      margin-left: auto;

      border-radius: 4px;

      background: #1aae9f;
      color: #fff;

      svg {
        margin-right: 4px;
      }
    }
  }

  ul {
    max-width: 800px;
  }
`;

export const CompanyContainer = styled.li`
  display: flex;
  align-items: center;

  background: #fff;

  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.4);

  padding: 4px;

  img {
    height: 104px;
    width: 128px;

    margin-right: 8px;
  }

  div {
    margin: 0 16px;
  }

  section {
    margin-top: 8px;
  }

  a {
    color: #fff;

    background: #788896;
    justify-content: center;

    display: flex;
    align-items: center;

    margin-left: auto;
    border-radius: 4px;

    transition: background-color 0.2s;

    &:hover {
      background: #1aae9f;
    }
  }

  & + li {
    margin-top: 16px;
  }
`;
