import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles, Scope } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import InputMask from "../../../components/form/Input/InputMask";
import Modal from "../../../components/Modal";
import api from "../../../services/api";
import getValidationErrors from "../../../utils/getValidationErrors";
import handleMessageError from "../../../utils/handleMessageError";
import {
  Container,
  InputsContainer,
  FormContent,
  ButtonsContainer,
} from "./styles";

interface ICompanySolicitation {
  id: string;
  name: string;
}

const CompanyRegister: React.FC = () => {
  const [companyRegister, setCompanyRegister] = useState<ICompanySolicitation>(
    {} as ICompanySolicitation,
  );
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const formRefProfile = useRef<FormHandles>(null);

  const { register_id } = useParams<{ register_id: string }>();

  const history = useHistory();

  useEffect(() => {
    api.get(`companies/requests/registers/${register_id}`).then(response => {
      const register = response.data;

      formRefProfile.current?.setData(register);
      setCompanyRegister(register);
    });
  }, [register_id]);

  const handleApproveRegister = useCallback(async () => {
    const toastId = toast.info("Aprovando cadastro...", {
      autoClose: false,
    });

    try {
      await api.post(
        `companies/requests/registers/${companyRegister.id}/approve`,
      );

      toast.update(toastId, {
        render: "Cadastro aprovado sucesso.",
        type: "success",
        autoClose: 3000,
      });

      history.push("/dashboard");
    } catch (error) {
      toast.update(toastId, {
        render: `Falha ao aprovar cadastro. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 6000,
      });
    }
  }, [companyRegister.id, history]);

  const handleDeclineSolicitation = useCallback(
    async description => {
      const schema = Yup.string().required();

      const toastId = toast.info("Recusando cadastro...", {
        autoClose: false,
      });

      try {
        await schema.validate(description, { abortEarly: false });

        await api.post(
          `companies/requests/registers/${companyRegister.id}/decline`,
          { description },
        );

        toast.update(toastId, {
          render: "Cadastro recusado. Em breve a empresa será notificada.",
          type: "warning",
          autoClose: 6000,
        });

        history.push("/dashboard");
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRefProfile.current?.setErrors(errors);

          toast.update(toastId, {
            render: "Favor verifique os campos.",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao recusar cadastro. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 6000,
        });
      }
    },
    [companyRegister.id, history],
  );

  return (
    <Container>
      <FormContent>
        <h1>Perfil da empresa</h1>
        <Form
          onSubmit={() => {
            console.log();
          }}
          ref={formRefProfile}
        >
          <InputsContainer>
            <h2>Dados da empresa</h2>
            <hr />

            <div>
              <Input
                disabled
                label="Nome da empresa"
                name="name"
                placeholder="Digite o nome"
              />
              <Input
                disabled
                label="CPF/CNPJ da empresa"
                name="cnpj"
                // maskType="cnpj"
              />

              <InputMask
                disabled
                label="Telefone de contato"
                name="phone"
                maskType="phone"
              />

              <Input
                disabled
                label="Inscrição estadual da empresa"
                name="inscricao_estadual"
                placeholder="Digite o número"
              />
              <Input
                disabled
                label="E-mail de contato"
                name="contact_email"
                type="email"
                placeholder="Digite o e-mail"
              />
            </div>
          </InputsContainer>

          <InputsContainer>
            <h2>Endereço</h2>
            <hr />

            <div>
              <Scope path="address">
                <InputMask
                  disabled
                  label="CEP"
                  name="zipcode"
                  maskType="cep"
                  alwaysShowMask
                  maskPlaceholder={null}
                />
                <Input
                  disabled
                  label="Estado"
                  name="state"
                  placeholder="Digite a sigla"
                  maxLength={2}
                />
                <Input
                  disabled
                  label="Cidade"
                  name="city"
                  placeholder="Digite o nome"
                />
                <Input
                  disabled
                  label="Bairro"
                  name="neighborhood"
                  placeholder="Digite o nome"
                />

                <Input
                  disabled
                  label="Rua"
                  name="street"
                  placeholder="Digite o nome"
                />
                <Input
                  disabled
                  label="Número"
                  name="number"
                  placeholder="Digite o número"
                />
                <Input
                  disabled
                  label="Complemento"
                  name="complement"
                  placeholder="Digite o complemento"
                />
              </Scope>
            </div>
          </InputsContainer>
        </Form>
        <ButtonsContainer>
          <button type="button" onClick={handleApproveRegister}>
            Aprovar cadastro da empresa
          </button>
          <button
            type="button"
            onClick={() => {
              setModalIsVisible(true);
            }}
          >
            Recusar
          </button>
        </ButtonsContainer>
      </FormContent>

      {modalIsVisible && (
        <Modal
          title={`Qual é o motivo de recusa da ${companyRegister.name}`}
          description="Esse motivo será enviado ao e-mail de contato que a empresa cadastrou"
          changeVisibility={() => {
            setModalIsVisible(false);
          }}
          handleSubmit={handleDeclineSolicitation}
        />
      )}
    </Container>
  );
};

export default CompanyRegister;
