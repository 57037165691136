import styled from "styled-components";

export const Container = styled.header`
  padding: 8px 16px;
  background: #fff;

  border-bottom: 1px solid #c5d0da;

  margin-bottom: 16px;
`;

export const Content = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: row;

  ul {
    display: flex;

    list-style: none;
  }
`;

export const PageItem = styled.li`
  a {
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }

  & + li {
    margin-left: 16px;
  }
`;

export const UserContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    margin-right: 8px;
  }

  img {
    width: 40px;
    border-radius: 50%;
  }
`;
