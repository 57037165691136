import React, { createContext, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";

import api from "../services/api";
import handleMessageError from "../utils/handleMessageError";

interface IUser {
  name: string;
  cpf: string;
  phone: string;
  email: string;
}

interface ISignCredentials {
  email: string;
  password: string;
}

interface IAuthData {
  user: IUser;
}

interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [authData, setAuthData] = useState<IAuthData>(() => {
    const user = localStorage.getItem("@ponto-loque:back-office:user");

    if (user) {
      api.get("/dashboard").catch(() => {
        localStorage.removeItem("@ponto-loque:back-office:user");

        toast.info("Faça login novamente", {
          toastId: "notification-to-renew-session",
        });

        setAuthData({} as IAuthData);
      });

      return { user: JSON.parse(user) };
    }

    return {} as IAuthData;
  });

  const signIn = useCallback(async ({ email, password }: ISignCredentials) => {
    const toastId = toast.info("Fazendo login...", { autoClose: false });

    try {
      const response = await api.post("/sessions", {
        email,
        password,
      });

      const user = response.data;

      localStorage.setItem(
        "@ponto-loque:back-office:user",
        JSON.stringify(user),
      );

      setAuthData({ user });

      toast.dismiss(toastId);
    } catch (error) {
      toast.update(toastId, {
        render: `Falha ao fazer login. Favor, verifique suas credenciais. ${handleMessageError(
          error,
        )}`,
        type: "error",
        autoClose: 5000,
      });
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@ponto-loque:back-office:user");
    setAuthData({} as IAuthContextData);
  }, []);

  const updateUser = useCallback((user: IUser) => {
    localStorage.setItem("@ponto-loque:back-office:user", JSON.stringify(user));

    setAuthData({ user });
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: authData.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
