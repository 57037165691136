import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1120px;
  width: 100%;

  height: 100%;

  margin: 0 auto;
`;
