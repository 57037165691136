import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";
import formatDate from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";
import { Container, CompanyContainer } from "./styles";

interface ICompanyRequestRegister {
  id: string;
  name: string;
  cnpj: string;
  created_at: string;
}
interface ICompanyRequestUpdate {
  id: string;
  created_at: string;
  company: {
    name: string;
    cnpj: string;
  };
}

const Dashboard: React.FC = () => {
  const [companyRegisters, setCompanyRegisters] = useState<
    ICompanyRequestRegister[]
  >([]);

  const [companyUpdate, setCompanyUpdate] = useState<ICompanyRequestUpdate[]>(
    [],
  );

  useEffect(() => {
    api.get("/companies/requests/registers").then(response => {
      setCompanyRegisters(response.data);
    });
  }, []);

  useEffect(() => {
    api.get("/companies/requests/updates").then(response => {
      setCompanyUpdate(response.data);
    });
  }, []);

  return (
    <Container>
      <section>
        <h2>Solicitações de cadastros</h2>
        <hr />

        <CompanyContainer>
          {companyRegisters.map(companyRegister => (
            <div key={companyRegister.id}>
              <strong>{companyRegister.name}</strong>
              <strong>
                {formatNumber({
                  data: companyRegister.cnpj,
                  type: companyRegister.cnpj.length > 12 ? "cnpj" : "cpf",
                })}
              </strong>
              <span>
                Data de cadastro:{" "}
                <strong> {formatDate(companyRegister.created_at)}</strong>
              </span>

              <Link to={`/companies/registers/${companyRegister.id}`}>
                Visualizar
              </Link>
            </div>
          ))}
        </CompanyContainer>
      </section>

      <section>
        <h2>Solicitações de alteração</h2>
        <hr />

        <CompanyContainer>
          {companyUpdate.map(companyRegister => (
            <div key={companyRegister.id}>
              <strong>{companyRegister.company.name}</strong>
              <strong>
                {formatNumber({
                  data: companyRegister.company.cnpj,
                  type:
                    companyRegister.company.cnpj.length > 12 ? "cnpj" : "cpf",
                })}
              </strong>
              <span>
                Data de cadastro:{" "}
                <strong> {formatDate(companyRegister.created_at)}</strong>
              </span>

              <Link to={`/companies/registers/${companyRegister.id}/updates`}>
                Visualizar
              </Link>
            </div>
          ))}
        </CompanyContainer>
      </section>
    </Container>
  );
};

export default Dashboard;
