import React from "react";
import {
  Redirect,
  Route as ReactDOMRouter,
  RouteProps as ReactDOMRouterProps,
} from "react-router-dom";

import { useAuth } from "../hooks/auth";
import DefaultLayout from "../styles/_layouts/DefaultLayout";

interface IRouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
}

const Route: React.FC<IRouteProps> = ({ isPrivate, ...rest }) => {
  const { user } = useAuth();

  if (user && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  if (!user && isPrivate) {
    return <Redirect to="/" />;
  }

  return (
    <DefaultLayout>
      <ReactDOMRouter {...rest} />
    </DefaultLayout>
  );
};

export default Route;
