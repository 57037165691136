import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 24px;

  main {
    background: #fff;
    border: 1px solid #c5d0da;
    border-radius: 4px;

    padding: 16px 40px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 54px;

    header {
      margin-bottom: 24px;
    }
  }
`;

export const CustomerContent = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  width: 100%;

  h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  section {
    border: 2px solid rgba(0, 0, 0, 0.4);
    padding: 8px;

    div {
      > strong {
        font-size: 1.6rem;
        font-weight: 700;
      }

      hr {
        margin-bottom: 8px;
      }

      p + p {
        margin-top: 4px;
      }

      & + div {
        margin-top: 16px;
      }
    }
  }

  button {
    width: 100%;

    margin-top: 16px;
    padding: 8px;

    border-radius: 4px;

    color: #fff;
    font-size: 2rem;

    background: #788896;

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }
`;
