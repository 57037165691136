import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InputSelect from "../../../components/form/Input/Select";
import api from "../../../services/api";
import formatNumber from "../../../utils/formatNumber";
import {
  Container,
  FormContainer,
  TableContainer,
  TableContent,
} from "./styles";

interface ITransactionData {
  id: string;
  amount: number;
  external_id: string;
  payment_method: string;
  card_last_digits: string;
  captured: boolean;
  cost_percentage: number;
  created_at: string;
}

interface IOrderData {
  id: number;
  product_name: string;
  quantity: number;
  rental_days: number;
  daily_rate_price: number;
  freight_price: number;
  expires_in: string;
  created_at: string;
  updated_at: string;
  transaction: ITransactionData;
  customer: {
    id: string;
    name: string;
    uid: string;
  };
}

interface ICompanyTransactionData {
  company_id: string;
  company_name: string;
  balance: {
    gross_revenue: number;
    cost: number;
    net_revenue: number;
  };
  orders: IOrderData[];
}
const CompanyFinancial: React.FC = () => {
  const { company_id } = useParams<{ company_id: string }>();

  const [companyTransaction, setCompanyTransaction] = useState<
    ICompanyTransactionData
  >();
  const [selectMonth, setSelectMonth] = useState(new Date().getMonth());
  const [selectYear, setSelectYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/companies/${company_id}/transactions`, {
        params: {
          month: selectMonth,
          year: selectYear,
        },
      })
      .then(response => {
        setCompanyTransaction(response.data);
        setLoading(false);
      });
  }, [company_id, selectMonth, selectYear]);

  const handleSubmit = useCallback(
    data => {
      if (data.month === selectMonth && data.year === selectYear) {
        return;
      }
      setLoading(true);
      setSelectMonth(data.month);
      setSelectYear(data.year);
    },
    [selectMonth, selectYear],
  );

  const formatCustomerData = useCallback((uid: string, name: string) => {
    const type = uid.length > 11 ? "cnpj" : "cpf";

    return `${formatNumber({ data: uid, type })} - ${name}`;
  }, []);

  if (!companyTransaction) {
    return (
      <Container>
        <main>
          <h1>{loading ? "Carregando..." : "Nenhum dado encontrado..."}</h1>
        </main>
      </Container>
    );
  }

  return (
    <Container>
      <main>
        <h1>
          Financeiro detalhado da empresa {companyTransaction.company_name}
        </h1>
        <FormContainer
          onSubmit={handleSubmit}
          initialData={{
            month: selectMonth,
            year: selectYear,
          }}
        >
          <div>
            <InputSelect
              label="Mês de referência"
              name="month"
              optionValues={[
                { label: "Janeiro - 01", value: 0 },
                { label: "Fevereiro - 02", value: 1 },
                { label: "Março - 03", value: 2 },
                { label: "Abril - 04", value: 3 },
                { label: "Maio - 05", value: 4 },
                { label: "Junho - 06", value: 5 },
                { label: "Julho - 07", value: 6 },
                { label: "Agosto - 08", value: 7 },
                { label: "Setembro - 09", value: 8 },
                { label: "Outubro - 10", value: 9 },
                { label: "Novembro - 11", value: 10 },
                { label: "Dezembro - 12", value: 11 },
              ]}
            />
            <InputSelect
              label="Ano de referência"
              name="year"
              optionValues={[
                { label: "2021", value: 2021 },
                { label: "2022", value: 2022 },
              ]}
            />
          </div>

          <button>Buscar dados</button>
        </FormContainer>

        {loading ? (
          "Carregando transações..."
        ) : (
          <TableContainer>
            <strong>
              {companyTransaction.orders.length}{" "}
              {companyTransaction.orders.length < 2
                ? "registro encontrado"
                : "registros encontrados"}
            </strong>
            <TableContent>
              <thead>
                <tr>
                  <th>ID do pedido</th>
                  <th>Cliente</th>
                  <th>Qt. de dias alugado</th>
                  <th>Nome do equipamento</th>
                  <th>Método de pagamento</th>
                  <th>Valor total bruto</th>
                </tr>
              </thead>
              <tbody>
                {companyTransaction.orders.map(order => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>
                      {formatCustomerData(
                        order.customer.uid,
                        order.customer.name,
                      )}
                    </td>
                    <td>{order.rental_days}</td>
                    <td>{order.product_name}</td>
                    <td>Cartão de crédito</td>
                    <td>
                      {formatNumber({
                        data: order.transaction.amount,
                        type: "currency",
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableContent>
          </TableContainer>
        )}

        <footer>
          <div>
            <ul>
              <li>
                <p>Faturado: </p>
                <strong>
                  {formatNumber({
                    data: companyTransaction.balance.gross_revenue,
                    type: "currency",
                  })}
                </strong>
              </li>

              <li>
                <p>Desconto: </p>

                <strong>
                  {formatNumber({
                    data: companyTransaction.balance.cost,
                    type: "currency",
                  })}
                </strong>
              </li>
              <li>
                <p>Saldo: </p>
                <strong>
                  {formatNumber({
                    data: companyTransaction.balance.net_revenue,
                    type: "currency",
                  })}
                </strong>
              </li>
            </ul>
          </div>
        </footer>
      </main>
    </Container>
  );
};

export default CompanyFinancial;
