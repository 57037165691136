import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppProvider from "./hooks";
import Routes from "./routes";
import GlobalStyle from "./styles/GlobalStyle";

function App(): JSX.Element {
  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppProvider>
    </>
  );
}

export default App;
