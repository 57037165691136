import React from "react";
import { Redirect, Switch } from "react-router-dom";

import ForgotPassword from "../pages/Auth/ForgotPassword";
import RegisterPassword from "../pages/Auth/RegisterPassword";
import SignIn from "../pages/Auth/SignIn";
import Companies from "../pages/Companies";
import Company from "../pages/Companies/Company";
import CompanyFinancial from "../pages/Companies/Financial";
import CompanyRegister from "../pages/Companies/Register";
import CompanyUpdate from "../pages/Companies/Update";
import Customers from "../pages/Customers";
import Customer from "../pages/Customers/Customer";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Route from "./Route";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />

      <Route path="/register-password" component={RegisterPassword} />
      <Route path="/forgot-password" component={ForgotPassword} />

      <Route path="/dashboard" isPrivate component={Dashboard} />
      <Route path="/profile" isPrivate component={Profile} />
      <Route exact path="/companies" isPrivate component={Companies} />
      <Route
        exact
        path="/companies/:company_id"
        isPrivate
        component={Company}
      />
      <Route
        exact
        path="/companies/:company_id/transactions"
        isPrivate
        component={CompanyFinancial}
      />

      <Route
        exact
        path="/companies/registers/:register_id"
        isPrivate
        component={CompanyRegister}
      />
      <Route
        exact
        path="/companies/registers/:register_id/updates"
        isPrivate
        component={CompanyUpdate}
      />

      <Route exact path="/customers" isPrivate component={Customers} />
      <Route
        exact
        path="/customers/:customer_id"
        isPrivate
        component={Customer}
      />

      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
