import { Form } from "@unform/web";
import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 24px;

  h1 {
    margin-bottom: 24px;
  }

  main {
    background: #fff;
    border: 1px solid #c5d0da;
    border-radius: 4px;

    padding: 16px 40px;

    footer {
      display: flex;
      width: 100%;

      div {
        margin-left: auto;

        ul {
          list-style: none;

          li {
            display: flex;
            justify-content: space-between;

            strong {
              margin-left: 8px;
            }

            & + li {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
`;

export const TableContainer = styled.table`
  margin: 24px auto;
  width: 100%;
`;

export const TableContent = styled.table`
  color: #000;
  text-align: center;

  border: 2px solid #c3cfd9;
  border-collapse: collapse;

  table,
  th,
  td {
    border: 1px solid #c3cfd9;
  }

  th {
    font-weight: 500;
    padding: 4px 16px;
  }

  td {
    padding: 4px;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  width: 100%;
  max-width: 320px;

  > div {
    display: flex;
    margin: 0 auto;

    div + div {
      margin-left: 16px;

      div {
        display: flex;
        flex-direction: column;
      }
    }
  }

  button {
    margin-top: 16px;

    border-radius: 8px;
    font-size: 2rem;
    padding: 8px;
  }
`;
