import React, { useState } from "react";
import { MdClose } from "react-icons/md";

import { Container, Content } from "./styles";

interface IModalProps {
  title: string;
  description: string;
  changeVisibility: Function;
  handleSubmit: Function;
}

const Modal: React.FC<IModalProps> = ({
  title,
  description,
  changeVisibility,
  handleSubmit,
}) => {
  const [descriptionValue, setDescriptionValue] = useState("");

  return (
    <Container>
      <Content>
        <header>
          <button
            onClick={() => {
              changeVisibility();
            }}
          >
            <MdClose />
          </button>
        </header>
        <p> {title}</p>

        <textarea
          value={descriptionValue}
          onChange={e => {
            setDescriptionValue(e.target.value);
          }}
          cols={24}
          rows={8}
          placeholder="Digite o motivo"
        ></textarea>

        <footer>
          <span>{description}</span>
          <button
            onClick={() => {
              handleSubmit(descriptionValue);
            }}
          >
            Relatar motivo
          </button>
        </footer>
      </Content>
    </Container>
  );
};

export default Modal;
