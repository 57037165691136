import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../../components/form/Input";
import Modal from "../../../components/Modal";
import api from "../../../services/api";
import formatDate from "../../../utils/formatDate";
import formatNumber from "../../../utils/formatNumber";
import handleMessageError from "../../../utils/handleMessageError";
import {
  Container,
  CompanyContent,
  FinancialContainer,
  CommissionContainer,
} from "./styles";

interface ICompanyData {
  id: string;
  name: string;
  cnpj: string;
  inscricao_estadual: string;
  email: string;
  contact_email: string;
  phone: string;
  approved_by_user_id: string;
  commission_fee: number;
  disabled: boolean;
  created_at: string;
  updated_at: string;
  address: {
    state: string;
    city: string;
    zipcode: string;
    neighborhood: string;
    street: string;
    number: string;
    complement: string;
    created_at: string;
    updated_at: string;
  };
  approved_by_user?: {
    name: string;
    cpf: string;
    email: string;
  };
}

interface IBalanceData {
  gross_revenue: number;
  cost: number;
  net_revenue: number;
}

const Company: React.FC = () => {
  const [company, setCompany] = useState<ICompanyData>();
  const [balance, setBalance] = useState<IBalanceData>();
  const [loading, setLoading] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { company_id } = useParams<{ company_id: string }>();

  useEffect(() => {
    api.get(`/companies/${company_id}`).then(response => {
      setCompany(response.data);

      formRef.current?.setData({
        commission_fee: response.data.commission_fee,
      });

      console.log(response.data.commission_fee);

      setLoading(false);
    });
  }, [company_id]);

  useEffect(() => {
    api.get(`/companies/${company_id}/transactions/balances`).then(response => {
      setBalance(response.data);
      setLoading(false);
    });
  }, [company_id]);

  const handleDisableCompany = useCallback(
    async description => {
      const schema = Yup.string().required();

      const toastId = toast.info("Inativando empresa...", {
        autoClose: false,
      });

      try {
        await schema.validate(description, { abortEarly: false });

        await api.post(`/companies/${company_id}/status/disable`, {
          description,
        });

        setCompany(state => {
          if (state) {
            return { ...state, disabled: true };
          }

          return state;
        });

        setModalIsVisible(false);

        toast.update(toastId, {
          render: "Empresa desativada. Em breve a ela será notificada.",
          type: "warning",
          autoClose: 5000,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          toast.update(toastId, {
            render: "Favor verifique os campos.",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao inativar empresa. ${handleMessageError(error)}`,
          type: "error",
          autoClose: 6000,
        });
      }
    },
    [company_id],
  );

  const handleActiveCompany = useCallback(async () => {
    const toastId = toast.info("Ativando empresa...", {
      autoClose: false,
    });

    try {
      await api.post(`/companies/${company_id}/status/active`);

      setCompany(state => {
        if (state) {
          return { ...state, disabled: false };
        }

        return state;
      });

      toast.update(toastId, {
        render: "Empresa ativada. Em breve a ela será notificada.",
        type: "success",
        autoClose: 5000,
      });
    } catch (error) {
      toast.update(toastId, {
        render: `Falha ao ativar empresa. ${handleMessageError(error)}`,
        type: "error",
        autoClose: 6000,
      });
    }
  }, [company_id]);

  const handleUpdateCommission = useCallback(
    async data => {
      const schema = Yup.number().required().min(0).max(100);

      formRef.current?.setErrors({});

      const commission_fee = Number(data.commission_fee);

      const toastId = toast.info("Atualizando comissão...", {
        autoClose: false,
      });

      try {
        await schema.validate(commission_fee, { abortEarly: false });

        await api.put(`/companies/${company_id}`, {
          commission_fee,
        });

        toast.dismiss(toastId);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          formRef.current?.setFieldError(
            "commission_fee",
            "O número deve ser entre 0 e 100",
          );

          toast.update(toastId, {
            render: "Favor verifique os campos.",
            type: "error",
            autoClose: 3000,
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao atualizar comissão da empresa. ${handleMessageError(
            error,
          )}`,
          type: "error",
          autoClose: 6000,
        });
      }
    },
    [company_id],
  );

  if (!company) {
    return (
      <Container>
        <main>
          <h1>{loading ? "Carregando..." : "Nenhuma empresa encontrada"}</h1>
        </main>
      </Container>
    );
  }

  return (
    <Container>
      <main>
        <section>
          <header>
            <h1>Perfil da empresa</h1>
          </header>
          <CompanyContent>
            <section>
              <div>
                <strong>Dados</strong>
                <hr />
                <p>
                  Nome: <strong>{company.name}</strong>
                </p>
                <p>
                  CNPJ: <strong>{company.cnpj}</strong>
                </p>
                <p>
                  Telefone de contato:
                  <strong> {company.phone}</strong>
                </p>
                <p>
                  E-mail:
                  <strong> {company.email}</strong>
                </p>
                <p>
                  E-mail de contato:
                  <strong> {company.contact_email}</strong>
                </p>
                <p>
                  Inscrição estadual:
                  <strong> {company.inscricao_estadual}</strong>
                </p>
              </div>
              <div>
                <strong>Endereço da empresa</strong>
                <hr />
                <p>
                  CEP: <strong>{company.address.zipcode}</strong>
                </p>
                <p>
                  Estado: <strong>{company.address.state}</strong>
                </p>
                <p>
                  Cidade: <strong>{company.address.city}</strong>
                </p>
                <p>
                  Bairro:
                  <strong> {company.address.neighborhood}</strong>
                </p>
                <p>
                  Rua:
                  <strong> {company.address.street}</strong>
                </p>
                <p>
                  Número:
                  <strong> {company.address.number}</strong>
                </p>
                <p>
                  Complemento:
                  <strong> {company.address.complement}</strong>
                </p>
              </div>

              <div>
                <strong>Dados adicionais</strong>
                <hr />

                <p>
                  Cadastrado em:
                  <strong> {formatDate(company.created_at)}</strong>
                </p>
                <p>
                  Status:
                  <strong>
                    {company.disabled ? " Desativada" : " Ativado"}
                  </strong>
                </p>
                <p>
                  Aprovado por:{" "}
                  <strong>
                    {company.approved_by_user?.name}
                    {" - "}
                    {company.approved_by_user?.email}
                  </strong>
                </p>
              </div>
            </section>
            {company.disabled ? (
              <button onClick={handleActiveCompany}>Ativar empresa</button>
            ) : (
              <button
                onClick={() => {
                  setModalIsVisible(true);
                }}
              >
                Inativar empresa
              </button>
            )}
          </CompanyContent>
        </section>

        <FinancialContainer>
          <header>
            <h1>Financeiro</h1>
            <Link to={`${company_id}/transactions`}>Ver detalhado</Link>
          </header>

          {!balance ? (
            <div>
              <p>Carregando...</p>
            </div>
          ) : (
            <div>
              <ul>
                <li>
                  <p>Faturado: </p>
                  <strong>
                    {formatNumber({
                      data: balance.gross_revenue,
                      type: "currency",
                    })}
                  </strong>
                </li>

                <li>
                  <p>Desconto: </p>

                  <strong>
                    {formatNumber({
                      data: balance.cost,
                      type: "currency",
                    })}
                  </strong>
                </li>
                <li>
                  <p>Saldo: </p>
                  <strong>
                    {formatNumber({
                      data: balance.net_revenue,
                      type: "currency",
                    })}
                  </strong>
                </li>
              </ul>
            </div>
          )}

          <CommissionContainer>
            <Form
              onSubmit={handleUpdateCommission}
              ref={formRef}
              initialData={company}
            >
              <Input
                label="Comissão (em %)"
                name="commission_fee"
                type="number"
                step="1"
                min="0"
                max="100"
              />

              <button type="submit">Atualizar comissão</button>
            </Form>
          </CommissionContainer>
        </FinancialContainer>
      </main>

      {modalIsVisible && (
        <Modal
          title={`Qual é o motivo de inativar a ${company.name}`}
          handleSubmit={handleDisableCompany}
          description="Esse motivo será enviado ao e-mail da empresa"
          changeVisibility={() => {
            setModalIsVisible(false);
          }}
        />
      )}
    </Container>
  );
};

export default Company;
