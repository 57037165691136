import React, { useCallback, useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

import { Form } from "@unform/web";

import Input from "../../components/form/Input";
import api from "../../services/api";
import formatDate from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";
import { Container, CustomerContainer } from "./styles";

interface ICustomerData {
  id: string;
  name: string;
  uid: string;
  created_at: string;
  updated_at: string;
}

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<ICustomerData[]>([]);

  useEffect(() => {
    api.get("/customers").then(response => {
      setCustomers(response.data);
    });
  }, []);

  const handleSubmit = useCallback(data => {
    api
      .get("/customers", {
        params: data,
      })
      .then(response => {
        setCustomers(response.data);
      });
  }, []);

  return (
    <Container>
      <header>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Pesquise uma empresa por nome ou CPF/CNPJ"
            name="search_uid"
            type="search"
            placeholder="Digite o nome ou CPF/CNPJ"
          />
        </Form>
      </header>
      <main>
        <ul>
          {customers.map(customer => (
            <CustomerContainer key={customer.id}>
              <img
                src="https://www.flaticon.com/svg/vstatic/svg/1001/1001209.svg?token=exp=1611284376~hmac=faa17e3747ecbf3721063ed1fd9df3eb"
                alt={customer.name}
              />
              <div>
                <span>
                  <strong>{customer.id}</strong>
                </span>
                <section>
                  <p>
                    <strong>{customer.name}</strong>
                  </p>
                  <p>
                    <strong>
                      {formatNumber({
                        data: customer.uid,
                        type: customer.uid.length > 11 ? "cnpj" : "cpf",
                      })}
                    </strong>
                  </p>
                </section>
                <section>
                  <p>
                    Data de cadastramento:
                    <strong> {formatDate(customer.created_at)}</strong>
                  </p>
                  <p>
                    Data da ultima atualização:
                    <strong> {formatDate(customer.updated_at)}</strong>
                  </p>
                </section>
              </div>

              <Link to={`/customers/${customer.id}`}>
                <MdKeyboardArrowRight size={40} />
              </Link>
            </CustomerContainer>
          ))}
        </ul>
      </main>
    </Container>
  );
};

export default Customers;
