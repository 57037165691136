import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import Input from "../../components/form/Input";
import InputMask from "../../components/form/Input/InputMask";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import getValidationErrors from "../../utils/getValidationErrors";
import handleMessageError from "../../utils/handleMessageError";
import { Container, InputsContainer, ButtonsContainer } from "./styles";

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { user, signOut, updateUser } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório"),
        cpf: Yup.string().required("Campo obrigatório"),
        email: Yup.string()
          .email("Deve ser no formato de e-mail")
          .required("Campo obrigatório"),
        password: Yup.string(),
        new_password: Yup.string().when("password", {
          is: value => value.length,

          then: Yup.string().min(8, "A senha deve conter no mínimo 8 dígitos."),
        }),
        new_password_confirmation: Yup.string().when("new_password", {
          is: value => value.length,

          then: Yup.string().oneOf(
            [Yup.ref("new_password")],
            "As senhas devem ser iguais",
          ),
        }),

        phone: Yup.string().required("Campo obrigatório"),
      });

      const toastId = toast.info("Atualizando informações...");

      try {
        await schema.validate(data, { abortEarly: false });
        const response = await api.put("/profiles", data);

        toast.update(toastId, {
          render: "Dados atualizados com sucesso",
          type: "success",
        });

        formRef.current?.reset(response.data);
        updateUser(response.data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const erros = getValidationErrors(error);

          formRef.current?.setErrors(erros);
          toast.update(toastId, {
            render: "Favor, verifique os campos",
            type: "error",
          });

          return;
        }

        toast.update(toastId, {
          render: `Falha ao atualizar seus dados. ${handleMessageError(error)}`,
          type: "error",
        });
      }
    },
    [updateUser],
  );

  return (
    <Container>
      <h1>Perfil</h1>
      <Form onSubmit={handleSubmit} initialData={user} ref={formRef}>
        <InputsContainer>
          <h2>Dados do usuário</h2>
          <hr />

          <div>
            <Input label="Seu nome" name="name" placeholder="Seu Nome" />
            <InputMask
              label="Seu CPF"
              name="cpf"
              maskType="cpf"
              maskPlaceholder={null}
              placeholder="Seu CPF"
            />
            <InputMask
              label="Telefone de contato"
              name="phone"
              maskType="phone"
              maskPlaceholder={null}
              placeholder="Seu número de telefone"
            />
          </div>
        </InputsContainer>

        <InputsContainer>
          <h2>Dados de acesso à conta</h2>
          <hr />

          <div>
            <Input label="E-mail" name="email" placeholder="Seu e-mail" />
            <Input
              label="Senha atual da conta"
              name="password"
              type="password"
              placeholder="Sua senha atual"
            />
            <Input
              label="Nova senha"
              name="new_password"
              type="password"
              placeholder="Digite a nova senha"
            />
            <Input
              label="Confirmação de senha"
              name="new_password_confirmation"
              type="password"
              placeholder="Confirme a nova senha"
            />
          </div>
        </InputsContainer>
        <ButtonsContainer>
          <button type="submit">Atualizar dados</button>
          <button type="button" onClick={signOut}>
            Sair
          </button>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default Profile;
